.base {
    font-size: var(--fs-16);
    color: #151515;
}

.Fs32Fw700White {
    font-size: var(--fs-32);
    color: rgba(247, 247, 247, 1);
}

.Fs18Fw600White {
    font-size: var(--fs-18);
    color: rgba(247, 247, 247, 1);
}

.Fs16Fw500White {
    font-size: var(--fs-16);
    color: rgba(247, 247, 247, 1);
}

.Fs16Fw500Muted {
    font-size: var(--fs-16);
    color: rgba(247, 247, 247, 0.4);
}

.Fs14Fw500Muted {
    font-size: var(--fs-14);
    color: rgba(247, 247, 247, 0.4);
}