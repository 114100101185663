.selectSearch {
    max-width: 250px;
    width: 100%;
    position: relative;

    .allBox {
        padding: 8px 16px;
        border-radius: 6px;
        font-weight: 500;
        box-shadow: 0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(0, 0, 0, .05);
        transition-timing-function: cubic-bezier(.4, 0, .2, 1);
        transition-duration: .15s;
        color: black;
        cursor: pointer;
        user-select: none;

        .boxSelect {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 5px;
            font-size: (--fs-14);
            font-weight: 400;

            .labelSearch {
                display: flex;
                align-items: center;
                gap: 5px;

                img {
                    width: 100%;
                    max-width: 21px;
                }
            }
        }

        .dopActive {
            color: #151515;
        }
    }

    .boxInputIcon {
        position: absolute;
        z-index: 1000;
        top: 42px;
        left: 0;
        border: 1px solid rgba(231, 231, 231, 1);
        overflow: hidden;
        border-radius: 9px;

        animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1);

        .boxFlexInputSearch {
            display: flex;
            gap: 10px;
            align-items: center;
            padding: 0.3rem 0.75rem;
            border-bottom: 1px solid rgba(231, 231, 231, 1);
            background-color: white;

            img {
                opacity: .5;
                width: 1rem;
                height: 1rem;
            }

            input {
                color: #151515;
                font-size: var(--fs-14);
                font-weight: 400;
                background-color: white;
            }

            .searchInput {
                width: 100%;
                padding: 5px;
            }
        }

        .ul {
            width: 100%;
            list-style-type: none;
            padding: 0.5rem;
            background-color: white;
            user-select: none;
            text-align: center;

            .boxImgLi {
                display: flex;
                align-items: center;
                gap: 5px;
                justify-content: left;
                background-color: white;
                border-radius: 4px;

                img {
                    width: 100%;
                    max-width: 21px;
                }

                &:hover {
                    background-color: hsl(240 4.8% 95.9%);
                    color: hsl(240 5.9% 10%);
                }

                li {
                    color: hsl(240 5.9% 10%);
                    font-size: 14px;
                    font-weight: 400;
                    z-index: 1000;
                    padding: 0.375rem 0.5rem;
                    display: flex;
                    align-items: center;
                    gap: 5px;
                }


            }

            .notfound {
                font-size: .875rem;
                line-height: 3;
                color: hsl(240 5.9% 10%) !important;
            }
        }
    }
}

@keyframes contentShow {
    from {
        opacity: 0;
        transform: scale(0.96);
    }

    to {
        opacity: 1;
        transform: scale(1);
    }
}

.SelectWithSearchGreen {
    max-width: 220px;

    .allBox {
        background: #00A651;
        padding: 0;

        .dopActive {
            color: #fff;
        }
    }
}

.SelectWithSearchGray {
    max-width: 100px;

    .allBox {
        background: #EFEFEF;
        padding: 12px;

        .dopActive {
            color: #495057;
        }
    }
}

.SelectWithSearchNoBg {
    max-width: 100px;

    .allBox {
        padding: 12px;

        .dopActive {
            color: #00A651;
        }
    }
}

.SelectWithSearchPurple {
    width: 115%;
    border: 1px solid rgba(55, 55, 84, 1);
    background: rgba(32, 30, 68, 1);
    border-radius: 12px;

    .allBox {
        padding: 12px;

        .dopActive {
            color: rgba(247, 247, 247, 1);
        }
    }
}

.SelectWithSearchNoBGInput {
    width: 100%;
    border: none;
    background: none;
    max-width: 120px;

    .allBox {
        padding: 12px;

        .dopActive {
            color: rgba(247, 247, 247, 1);
        }
    }
}