.wrapper {
    display: flex;
    flex-direction: column;
    gap: 48px;
    margin-top: 24px;
    margin-bottom: 24px;
    align-items: center;

    .texts {
        margin-top: 48px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        text-align: center;
        max-width: 800px;
        width: 100%;
    }

    .filters {
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: space-between;
        width: 100%;

        .leftSide {
            display: flex;
            align-items: center;
            gap: 8px;
        }

        .purpleBtn {
            padding: 12px;

            .rotate {
                transform: rotate(90deg);
                animation: rotate 1s infinite;
            }
        }
    }

    .postsBlock {
        display: flex;
        flex-direction: column;
        border: 1px solid rgba(55, 55, 84, 1);
        border-radius: 12px;
        overflow: hidden;
        width: 100%;

        .head {
            border-bottom: 1px solid rgba(55, 55, 84, 1);
            background-color: rgba(32, 30, 68, 1);
            display: flex;
            align-items: center;
            gap: 10px;
            padding: 8px 16px;

            .h1,
            .h2,
            .h3,
            .h4 {
                width: 100%;
            }

            .h1 {
                max-width: 230px;
            }

            .h2 {
                max-width: 210px;
                cursor: pointer;
            }

            .h3 {
                max-width: 340px;
            }
        }

        .posts {
            display: flex;
            flex-direction: column;

            &.active {
                background: rgba(55, 55, 84, 1);
            }

            .column {
                display: flex;
                flex-direction: column;

                .post {
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    padding: 16px;



                    .opl {
                        display: flex;
                        align-items: center;
                        gap: 8px;

                        img {
                            border-radius: 999px;
                            width: 32px;
                            height: 32px;
                        }
                    }

                    .more {
                        background: rgba(55, 55, 84, 1);
                        padding: 12px;

                        &.activeBtn {
                            background: rgba(55, 55, 84, 1);
                            border: 1px solid rgba(32, 30, 68, 1);
                        }
                    }
                }

                .details {
                    display: flex;
                    flex-direction: column;

                }
            }

        }
    }
}

.inp {
    width: 220%;
    max-width: 500px;
}

.widthselect {
    width: 110%;
    max-width: 500px;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(180deg);
    }
}

.selectBlock {
    display: flex;
    gap: 12px;
    align-items: center;
    justify-content: center;
    background-color: rgba(32, 30, 68, 1);
    border: 1px solid rgba(55, 55, 84, 1);
    border-radius: 12px;
    padding: 4px;
}

.opl,
.cost,
.limits,
.h4 {
    width: 100%;
}

.opl {
    max-width: 230px;
}

.cost {
    max-width: 210px;
}

.limits {
    max-width: 340px;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.box {
    display: flex;
    align-items: center;
    gap: 8px;
    width: 200%;
    justify-content: space-between;
    border: 1px solid rgba(55, 55, 84, 1);
    background-color: rgba(32, 30, 68, 1);
    padding: 0 0 0 12px;
    border-radius: 12px;

    input {
        width: 100%;
        border-radius: 4px;
        font-size: var(--fs-14);
        color: rgb(238, 239, 252);
        appearance: none;
    }
}