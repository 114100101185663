@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    overflow-x: hidden;
    min-height: 700px;
}

body:has(> [data-state="open"]),
body:has(> [data-radix-focus-guard]) {
    margin: 0 !important;
    overflow: auto !important;
}

input {
    all: unset;
    box-sizing: border-box;
}

body {
    font-family: 'Inter';
    color: rgba(247, 247, 247, 1);
    background: rgba(11, 13, 35, 1);
}

a {
    color: inherit;
    text-decoration: none;
}

:root {
    --fs-8: 8px;
    --fs-10: 10px;
    --fs-12: 12px;
    --fs-13: 13px;
    --fs-15: 15px;
    --fs-16: 16px;
    --fs-18: 18px;
    --fs-20: 20px;
    --fs-25: 25px;
    --fs-32: 32px;
}

:root {
    --white: #FFFFFF;
    --dark: #252525;
    --gray: #808080;
    --blueLink: #8364E2;
    --whiteTransparent: #FFFFFF80;
    --gray2: #727272;
    --selagoGradient: linear-gradient(45deg, rgba(var(--secondary-color-rgb), .2) 0%, rgba(var(--secondary-color-rgb-2), .2) 50%);
    --selagoGradientTransparent: linear-gradient(45deg, rgba(var(--secondary-color-rgb), .1) 0%, rgba(var(--secondary-color-rgb-2), .1) 50%);
    --selagoGradient2: linear-gradient(to right, var(--secondary-color) 0%, var(--secondary-color-2) 100%);
    --secondary-color: #1AA7EC;
    --secondary-color-2: #797EF6;
    --secondary-color-rgb: 26, 167, 236;
    --secondary-color-rgb-2: 121, 126, 246;
    --secondary-color-rgb-3: 131, 100, 226;
    --primary-color: #040836;
    --primary-color-rgb: 4, 8, 54;
    --muffledWhite: #eeeeee;
    --thunderBird: #f24e1e;
    --blue-gradient: linear-gradient(95.41deg, #8E2DE2 -5.21%, #B3A0FF -5.2%, #8987FF 52.56%, #5135FF 107.29%);
    --dark-gradient: linear-gradient(95.41deg, #342e3a -5.21%, #1d1b24 -5.2%, #262548 52.56%, #130c3c 107.29%);
}

:root {
    --speed-fast: 0.1s;
    --speed-regularTransition: 0.25s;
    --speed-slowTransition: 0.7s;
}